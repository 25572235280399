<template>
   <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Create New Story</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleCreate">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Description <span>*</span></label>
                  <div class="has-label">
                    <input class="form-control" name="description" id="description" type="text" placeholder="description" v-model="story.description" required>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Created At</label>
                  <div class="has-label">
                    <input class="form-control" name="date" id="date" type="date" placeholder="Created At" v-model="story.created_at">
                  </div>
                </div>
                <!-- <base-input
                  name="created_at"
                  label="Created At"
                  placeholder="Created At"
                  type="date"
                /> -->
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">ImagePath</label>
                  <div class="has-label">
                    <input class="form-control" name="imagePath" id="imagePath" type="text" placeholder="ImagePath" v-model="story.imagePath">
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">FilePath</label>
                  <div class="has-label">
                    <input class="form-control" name="filePath" id="filePath" type="text" placeholder="FilePath" v-model="story.filePath">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Creator ID</label>
                  <div class="has-label">
                    <input class="form-control" name="creator_id" id="creator_id" type="text" placeholder="Creator ID" v-model="story.creator_id">
                  </div>
                </div>
                <!-- <base-input
                  name="creator_id"
                  label="Creator ID"
                  required
                  placeholder="Creator ID"
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Creator Name</label>
                  <div class="has-label">
                    <input class="form-control" name="creator_name" id="creator_name" type="text" placeholder="Creator Name" v-model="story.creator_name">
                  </div>
                </div>
                <!-- <base-input
                  name="creator_name"
                  label="Creator Name"
                  placeholder="Creator Name"
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group" style="margin-bottom: .5rem;">
                  <label class="form-control-label">IsPrivate</label>
                </div>
                <base-checkbox name="isPrivate" v-model="story.isPrivate" class="mb-3"></base-checkbox>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Jourly ID</label>
                  <div class="has-label">
                    <input class="form-control" name="jourlyId" id="jourlyId" type="text" placeholder="Jourly ID" v-model="story.jourlyId">
                  </div>
                </div>
                <!-- <base-input
                  name="jourlyId"
                  label="Jourly ID"
                  placeholder="Jourly ID"
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Stop ID</label>
                  <div class="has-label">
                    <input class="form-control" name="stopId" id="stopId" type="text" placeholder="Stop ID" v-model="story.stopId">
                  </div>
                </div>
                <!-- <base-input
                  name="stopId"
                  label="Stop ID"
                  placeholder="Stop ID"
                /> -->
              </div>
              
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Tag User ID</label>
                  <div class="has-label">
                    <input class="form-control" name="tag_user_id" id="tag_user_id" type="text" placeholder="Tag User ID" v-model="story.tag_user_id">
                  </div>
                </div>
                <!-- <base-input
                  name="tag_user_id"
                  label="Tag User ID"
                  placeholder="Tag User ID"
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Tag User Name</label>
                  <div class="has-label">
                    <input class="form-control" name="tag_user_name" id="tag_user_name" type="text" placeholder="Tag User Name" v-model="story.tag_user_name">
                  </div>
                </div>
                <!-- <base-input
                  name="tag_user_name"
                  label="Tag User Name"
                  placeholder="Tag User Name"
                /> -->
              </div>
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Create</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      story:{
	      created_at : "",
	      creator_id : "",
	      creator_name : "",
	      description : "",
	      filePath : "",
	      imagePath : "",
	      isPrivate : false,
	      jourlyId : "",
	      stopId : "",
	      tag_user_id : "",
	      tag_user_name : ""
      }
    };
  },
  methods: {
    async handleCreate() {
      console.log(this.story);
      let response =  await RequestService.post('story/store',this.story);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/story/index");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
