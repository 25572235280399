<template>
   <div class="content" style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div v-if="!loading">
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">List</h3>
            <p class="text-sm mb-0" v-if="showPagination">
              List of all lists.
            </p>
            <p class="text-sm mb-0" v-else>
              Lists Filtered by {{searchColumn}} = '{{searchQuery}}'
            </p>
          </template>
          <div>
            <!-- <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="row" style="margin-left:auto">
                <div class="col-md-3">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="searchColumn"
                    placeholder="Select Column"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in tableColumnNames"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-md-4">
                  <div class="el-input  el-input--prefix el-input--suffix mb-3" style="width: 100%;">
                    <input class="el-input__inner" type="search" autocomplete="off" tabindex="" aria-label="" v-model="searchQuery" placeholder="Search records">
                    <span class="el-input__prefix"><i class="el-input__icon el-icon-search"></i></span>
                  </div>
                </div>
                <div class="col-md-5">
                  <button type="button" class="btn base-button btn-info btn-md" @click.stop="onSearch">Search</button>
                  <button type="button" class="btn base-button btn-primary btn-md" v-if="!showPagination" @click.stop="getData">Back to Index</button>
                </div>
              </div>
            </div> -->
            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <el-tooltip placement="top" content="Edit">
                      <base-button
                        @click="handleEdit(props.$index, props.row)"
                        class="edit"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-ruler-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Delete">
                      <base-button
                        @click="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-fat-remove"></i>
                      </base-button>
                    </el-tooltip>
                    <base-button
                      @click="handleItems(props.$index, props.row)"
                      class="info"
                      type="primary"
                      size="sm"
                      icon
                    >
                      Items
                    </base-button>
                    <base-button
                      v-if="props.row.collaborators.length>0"
                      @click="handleCollaborators(props.$index, props.row)"
                      class="remove btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <span class="text-white">Collaborators</span>
                    </base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  <!-- Showing {{ from + 1 }} to {{ to }} of {{ total }} entries -->
                </p>
              </div>
              <ul class="pagination pagination-primary pagination-no-border" v-if="showPagination">
                <li class="page-item prev-page">
                  <a class="page-link" aria-label="Previous" @click="getData('firstList='+prev_page)">
                    <i class="fa fa-angle-left"></i>
                  </a>
                </li>
                <li class="page-item active"><a class="page-link"><i class="fa fa-pause"></i></a></li>
                <li class="page-item page-pre next-page">
                  <a class="page-link" aria-label="Next" @click="getData('lastList='+next_page)"><i class="fa fa-angle-right"></i></a>
                </li>
              </ul>
              <!-- <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination> -->
            </div>
          </template>
        </card>
      </div>
      <div v-else style="text-align:center;padding:100px">
        <img src="/img/loading.gif"/>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import RequestService from '../../services/request.service';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  components: {
    // BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    // pagedData() {
    //   return this.tableData.slice(this.from, this.to);
    // },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    
  },
  data() {
    return {
      message:'',
      tableColumnNames: ["username"],
      // tableColumnNames: ["id", "bio", "email", "imagePath", "username", "website", "status"],
      searchQuery: "",
      searchColumn: "",
      propsToSearch: ["id", "username"],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          // minWidth: 200,
        },
        {
          prop: "name",
          label: "Name",
          // minWidth: 200,
        },
        {
          prop: "itemChecked",
          label: "ItemChecked",
          // minWidth: 200,
        },
        {
          prop: "itemCount",
          label: "ItemCount",
          // minWidth: 200,
        },
        {
          prop: "username",
          label: "Username",
          // minWidth: 200,
        },
        {
          prop: "userId",
          label: "UserId",
          // minWidth: 200,
        },
        {
          prop: "privacy",
          label: "Privacy",
          // minWidth: 200,
        },
        {
          prop: "likeCount",
          label: "Like Count",
          // minWidth: 200,
        },
      ],
      tableData: [],
      next_page:'',
      prev_page:'',
      fuseSearch: null,
      searchedData: [],
      showPagination:true,
      loading:true
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async onSearch(){
      this.loading = true;
      if(this.searchQuery!=''){
        if(this.searchColumn!=''){
          this.showPagination = false;
          let apiUrl = 'jourly-user/search?search='+this.searchQuery;
          let response =  await RequestService.get(apiUrl);

          if(response){
            this.tableData = response;
          }else{
            this.message = 'Something went worng. Please try again.';
            this.runToast('top-right', 'warning');
          }  
        }else{
          this.message = 'Please Select the Search Column and Try Again.';
          this.runToast('top-right', 'warning');
        }
      }else{
        this.message = 'Please Fill the Search Field and Try Again.';
        this.runToast('top-right', 'warning');
      }
      this.loading = false;
    },
    async getData(url = null){
      this.loading = true;
      let apiUrl='';
      if(url){
          apiUrl = 'lists?'+url;
      }else{
        apiUrl = 'lists';
      }
      let response =  await RequestService.get(apiUrl);
      this.showPagination = true;
      this.searchQuery = '';
      this.searchColumn = '';
      if(response){
        if(this.showPagination){
          this.next_page = response.next_page.split('lastList=')[1];
          this.prev_page = response.prev_page.split('firstList=')[1];
          this.tableData = response.lists.original;
        }else{
          this.tableData = response;
        }
        
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }      
      this.loading = false;
    },
    handleCollaborators(index, row) {
      this.$router.push("/list/"+row.id+"/collaborators");
    },
    handleEdit(index, row) {
      this.$router.push("/list/edit/"+row.id);
    },
    handleItems(index,row){
      this.$router.push("/list/"+row.id+"/items");
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              text: `You deleted ${row.name}`,
            });
          }
        });
    },
    async deleteRow(row) {
      await RequestService.delete('list/'+row.id+'/delete');
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      // const text =
      //   "Something went wrong. Please try again.";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
