<template>
  <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Details Jourly</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-6 col-sm-12" v-for="(value, name, index) in jourly1" :key="index">
              <div class="form-group">
                <label class="form-control-label" style="text-transform: capitalize" v-if="name=='imagePath'">Image</label>
                <label class="form-control-label" style="text-transform: capitalize" v-else>{{name}}</label>
                <div class="has-label">
                  <img v-if="name=='imagePath'" :src="value" alt="Jourly Image" width="200">
                  <p v-else>{{value}}</p>
                  <!-- <input class="form-control"  type="text" :value="value" disabled> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-control-label">Name</label>
                <div class="has-label">
                  <input class="form-control" name="name" id="name" type="text" placeholder="Name" v-model="jourly.name" disabled>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-control-label">Date</label>
                <div class="has-label">
                  <input class="form-control" name="date" id="date" type="date" placeholder="Date" v-model="jourly.date" disabled>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-control-label">ImagePath</label>
                <div class="has-label">
                  <input class="form-control" name="imagePath" id="imagePath" type="text" placeholder="ImagePath" v-model="jourly.imagePath" disabled>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-control-label">User ID</label>
                <div class="has-label">
                  <input class="form-control" name="userId" id="userId" type="text" placeholder="UserID" required v-model="jourly.userId" disabled>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-control-label">Story Count</label>
                <div class="has-label">
                  <input class="form-control" name="storyCount" id="storyCount" type="number" placeholder="Story Count" v-model="jourly.storyCount" disabled>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group" style="margin-bottom: .5rem;">
                <label class="form-control-label">IsPreffered</label>
              </div>
              <base-checkbox name="isPreffered" v-model="jourly.isPreffered" disabled class="mb-3"></base-checkbox>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group" style="margin-bottom: .5rem;">
                <label class="form-control-label">IsFeatured</label>
              </div>
              <base-checkbox name="isFeatured" v-model="jourly.isFeatured" class="mb-3" disabled></base-checkbox>
            </div> -->
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useRoute } from 'vue-router';
// import { useToast } from "vue-toastification";
// import Notification from "@/components/Notification";


export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      jourlyId : '',
      message : '',
      jourly:{
	      name: '',
	      date : '',
	      imagePath : '',
	      isPreffered : false,
	      isFeatured : false,
	      storyCount : 0,
	      userId: ''
      },
      jourly1:[]
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(){
      let route = useRoute();
      this.jourlyId = route.params.id;
      let response1 =  await RequestService.get('jourly/'+this.jourlyId+'/details');
      this.jourly1 = response1;
      console.log(this.jourly1);
      let response =  await RequestService.get('jourly/'+this.jourlyId+'/show');
      this.jourly.name = response.name;
      this.jourly.date = response.date;
      this.jourly.imagePath = response.imagePath;
      this.jourly.isPreffered = response.isPreffered;
      this.jourly.isFeatured = response.isFeatured;
      this.jourly.storyCount = response.storyCount;
      this.jourly.userId = response.userId;
    },
  },
};
</script>
