<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Create an account</h1>
              <p class="text-lead text-white">
                <!-- Use these awesome forms to login or create new account in your
                project for free. -->
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card bg-secondary border-0">
            <!-- <div class="card-header bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div class="text-center">
                <a href="#" class="btn btn-neutral btn-icon mr-4">
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/github.svg"
                  /></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/google.svg"
                  /></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </div> -->
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Sign up with credentials</small>
              </div>
              <Form @submit="handleRegister" :validation-schema="schema">
                <base-input
                  alternative
                  class="mb-3"
                  addon-left-icon="ni ni-hat-3"
                  placeholder="Name"
                  name="name"
                >
                </base-input>

                <base-input
                  alternative
                  class="mb-3"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Email"
                  name="email"
                >
                </base-input>

                <base-input
                  alternative
                  class="mb-3"
                  addon-left-icon="ni ni-lock-circle-open"
                  placeholder="Password"
                  type="password"
                  name="password"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-3"
                  addon-left-icon="ni ni-lock-circle-open"
                  placeholder="Password Confirmation"
                  type="password"
                  name="password_confirmation"
                >
                </base-input>
                <!-- <div class="text-muted font-italic">
                  <small
                    >password strength:
                    <span class="text-success font-weight-700"
                      >strong</span
                    ></small
                  >
                </div> -->
                <div class="row my-4">
                  <div class="col-12">
                    <base-input name="Privacy Policy">
                      <base-checkbox v-model="model.agree">
                        <span class="text-muted"
                          >I agree with the
                          <a href="#!">Privacy Policy</a></span
                        >
                      </base-checkbox>
                    </base-input>
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary mt-4">
                    Create account
                  </button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-right">
              <router-link to="/login" class="text-light">
                <small>Login</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";

export default {
  name: "register",
  components: {
    Form,
  },
  data() {
    return {
      model: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        agree: false,
      },
    };
  },
  setup() {

    const schema = Yup.object().shape({
      name: Yup.string().required().label("The Full Name"),
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(5).required().label("The Password"),
    });
    return {
      schema,
    };
  },
   methods: {
    handleRegister(user) {
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        () => {
          this.$router.push("/login");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.runToast('top-right', 'warning')
        }
      );
    },
    runToast(pos, type, ownText, ownIcon) {
      // const text =
      //   "Something went wrong. Please try again.";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
<style></style>
