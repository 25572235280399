<template>
  <!-- <div></div> -->
  <footer class="footer" style="background: #000000;">
    <!-- <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://webleon.de/"
            class="font-weight-bold ml-1"
            target="_blank"
            >Webleon</a
          >
        </div>
      </div>
      <div class="col-lg-6" style="text-align:right">
        <div class="text-muted">
          Developed by
           <a
            href="https://infancyit.com"
            class="font-weight-bold ml-1"
            target="_blank"
            >InfancyIT</a
          >
        </div>
      </div>
    </div> -->
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
