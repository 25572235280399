<template>
   <div class="content" style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div v-if="!loading">
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">List Collaborators</h3>
            <p class="text-sm mb-0">
              List of List Collaborators.
            </p>
          </template>
          <div>
            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column max-width="140px" align="right" label="Profile Image">
                <template v-slot:default="props">
                  <div class="d-flex" v-if="props.row.thumbImagePath">
                    <img :src="props.row.thumbImagePath" alt="Jourly User Profile Image" width="130">
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <el-tooltip placement="top" content="Change Collaborator">
                      <base-button
                        @click="handleEdit(props.$index, props.row)"
                        class="edit"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-ruler-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Remove this Collaborator">
                      <base-button
                        @click="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-fat-remove"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              
              
            </div>
          </template>
        </card>
      </div>
      <div v-else style="text-align:center;padding:100px">
        <img src="/img/loading.gif"/>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import RequestService from '../../services/request.service';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import { useRoute } from 'vue-router';

export default {
  components: {
    // BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    // pagedData() {
    //   return this.tableData.slice(this.from, this.to);
    // },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    
  },
  data() {
    return {
      message:'',
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          // minWidth: 200,
        },
        // {
        //   prop: "bio",
        //   label: "Bio",
        //   // minWidth: 200,
        // },
        {
          prop: "email",
          label: "Email",
          // minWidth: 200,
        },
        // {
        //   prop: "imagePath",
        //   label: "Image Path",
        //   // minWidth: 200,
        // },
        {
          prop: "username",
          label: "Username",
          // minWidth: 200,
        },
        {
          prop: "website",
          label: "Website",
          // minWidth: 200,
        },
        // {
        //   prop: "status",
        //   label: "Status",
        //   // minWidth: 200,
        // },
        // {
        //   prop: "count_jourly",
        //   label: "Count Jourly",
        //   // minWidth: 200,
        // },
        // {
        //   prop: "count_story",
        //   label: "Count Story",
        //   // minWidth: 200,
        // },
        // {
        //   prop: "storage_used",
        //   label: "Storage Used (In MB)",
        //   // minWidth: 200,
        // },
      ],
      tableData: [],
      loading: true,
      listId: ''
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(url = null){
      let route = useRoute();
      this.listId = route.params.id;
      this.loading = true;
      let apiUrl = 'list/'+this.listId+'/collaborators';
      let response =  await RequestService.get(apiUrl);
      if(response){
          this.tableData = response;
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }      
      this.loading = false;
    },
    handleEdit(index, row) {
      this.$router.push("/list/"+this.listId+"/collaborator/edit/"+row.id);
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              text: `You deleted ${row.id}`,
            });
          }
        });
    },
    async deleteRow(row) {
      await RequestService.get('list/'+this.listId+'/collaborator/'+row.id+'/delete');
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      // const text =
      //   "Something went wrong. Please try again.";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async changeRow(row,status) {
      await RequestService.get('jourly-user/'+row.id+'/'+status);
      let indexToChange = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToChange >= 0) {
        this.tableData[indexToChange].status = status;
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
