<template>
   <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <div class="row">
            <div class="col-md-8" style="text-align:left">
              <h3 class="mb-0">Add New Item</h3>
            </div>
            <div class="col-md-4" style="text-align:right">
              <button type="button" class="btn btn-primary btn-sm" @click.stop="backToItems">Back to Items</button>
            </div>
          </div> 
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleCreate">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Name</label>
                  <div class="has-label">
                    <input
                      class="form-control"
                      name="name"
                      placeholder="Name"
                      v-model="item.name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Checked</label>
                  <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="item.checked" id="defaultCheck1">
              </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-group">
                  <label class="form-control-label">OrderIndex</label>
                  <div class="has-label">
                      <input
                        class="form-control"
                        name="orderIndex"
                        type="number"
                        placeholder="OrderIndex"
                        v-model="item.orderIndex"
                      />
                    </div>
                  </div>
              </div>
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Create</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../../services/request.service';
import { useRoute } from 'vue-router';

import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      listId:'',
      item:{
	      name: '',
	      checked : false,
	      orderIndex : 0,
      }
    };
  },
  mounted(){
    let route = useRoute();
    this.listId = route.params.id;
  },
  methods: {
    backToItems(){
      this.$router.push("/list/"+this.listId+"/items");
    },
    async handleCreate() {
      let response =  await RequestService.post('list/'+this.listId+'/item/store',this.item);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/list/"+this.listId+"/items");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
