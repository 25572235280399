<template>
  <div class="content" style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div v-if="!loading">
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Featured Jourlys</h3>
            <p class="text-sm mb-0">
              List of all featured jourlys.
            </p>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="row" style="margin-left:auto">
                <!-- <div class="col-md-4">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="searchColumn"
                    placeholder="Select Column"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in tableColumnNames"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-md-4">
                  <div class="el-input  el-input--prefix el-input--suffix mb-3" style="width: 100%;">
                    <input class="el-input__inner" type="search" autocomplete="off" tabindex="" aria-label="" v-model="searchQuery" placeholder="Search records">
                    <span class="el-input__prefix"><i class="el-input__icon el-icon-search"></i></span>
                  </div>
                </div>
                <div class="col-md-4">
                  <button type="button" class="btn base-button btn-info btn-md" @click.stop="onSearch">Search</button>
                </div> -->
              </div>
            </div>
            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column max-width="140px" align="right" label="Image">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <img :src="props.row.imagePath" alt="Jourly Image" width="130">
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <el-tooltip placement="top" content="Edit">
                      <base-button
                        @click="handleEdit(props.$index, props.row)"
                        class="edit"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-ruler-pencil"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Delete">
                      <base-button
                        @click="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-fat-remove"></i>
                      </base-button>
                    </el-tooltip>
                    <base-button
                      v-if="props.row.isFeatured=='true'"
                      @click="unfeaturedJourly(props.$index, props.row)"
                      class="remove btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <span class="text-white">Remove from featured</span>
                    </base-button>
                    <base-button
                      v-else
                      @click="featuredJourly(props.$index, props.row)"
                      class="remove btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <span class="text-white">Make Featured</span>
                    </base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  <!-- Showing {{ from + 1 }} to {{ to }} of {{ total }} entries -->
                </p>
              </div>
              <ul class="pagination pagination-primary pagination-no-border" v-if="showPagination">
                <li class="page-item prev-page">
                  <a class="page-link" aria-label="Previous" @click="getData('firstJourly='+prev_page)">
                    <i class="fa fa-angle-left"></i>
                  </a>
                </li>
                <li class="page-item active"><a class="page-link"><i class="fa fa-pause"></i></a></li>
                <li class="page-item page-pre next-page">
                  <a class="page-link" aria-label="Next" @click="getData('lastJourly='+next_page)"><i class="fa fa-angle-right"></i></a>
                </li>
              </ul>
              <!-- <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination> -->
            </div>
          </template>
        </card>
      </div>
      <div v-else style="text-align:center;padding:100px">
        <img src="/img/loading.gif"/>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import RequestService from '../../services/request.service';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  components: {
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    // pagedData() {
    //   return this.tableData.slice(this.from, this.to);
    // },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    // queriedData() {
    //   if (!this.searchQuery) {
    //     return this.pagedData;
    //   }
    //   let result = this.tableData.filter((row) => {
    //     let isIncluded = false;
    //     for (let key of this.propsToSearch) {
    //       let rowValue = row[key].toString();
    //       if (rowValue.includes && rowValue.includes(this.searchQuery)) {
    //         isIncluded = true;
    //       }
    //     }
    //     return isIncluded;
    //   });

    //   return result.slice(this.from, this.to);
    // },
    // to() {
    //   let highBound = this.from + this.pagination.perPage;
    //   if (this.total < highBound) {
    //     highBound = this.total;
    //   }
    //   return highBound;
    // },
    // from() {
    //   return this.pagination.perPage * (this.pagination.currentPage - 1);
    // },
    // total() {
    //   return this.searchedData.length > 0
    //     ? this.searchedData.length
    //     : this.tableData.length;
    // },
  },
  data() {
    return {
      message:'',
      // pagination: {
      //   perPage: 5,
      //   currentPage: 1,
      //   perPageOptions: [5, 10, 25, 50],
      //   total: 0,
      // },
      searchQuery: "",
      searchColumn: "",
      tableColumnNames: ["id", "name", "imagePath", "storyCount", "userId"],
      propsToSearch: ["name", "id","userId"],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          // minWidth: 200,
        },
        {
          prop: "name",
          label: "Name",
          // minWidth: 200,
        },
        // {
        //   prop: "imagePath",
        //   label: "Image Path",
        //   // minWidth: 200,
        // },
        {
          prop: "isFeatured",
          label: "isFeatured",
          // minWidth: 200,
        },
        {
          prop: "isPreffered",
          label: "isPreffered",
          // minWidth: 200,
        },
        {
          prop: "storyCount",
          label: "storyCount",
          // minWidth: 200,
        },
        {
          prop: "username",
          label: "username",
          // minWidth: 200,
        },
        {
          prop: "privacy",
          label: "privacy",
          // minWidth: 200,
        },
        {
          prop: "likeCount",
          label: "like Count",
          // minWidth: 200,
        },
      ],
      tableData: [],
      next_page:'',
      prev_page:'',
      fuseSearch: null,
      searchedData: [],
      showPagination : true,
      loading:true
    };
  },
  mounted(){
    ///jourlys?firstJourly=Test Jourly
    this.getData();
    
  },
  methods: {
    async onSearch(){
      this.loading = true;
      if(this.searchQuery!=''){
        if(this.searchColumn!=''){
          this.showPagination = false;
          let apiUrl = 'jourly/search?search='+this.searchQuery+'&column='+this.searchColumn;
          let response =  await RequestService.get(apiUrl);
          if(response){
            this.tableData = response;
          }else{
            this.message = 'Something went worng. Please try again.';
            this.runToast('top-right', 'warning');
          }  
        }else{
          this.message = 'Please Select the Search Column and Try Again.';
          this.runToast('top-right', 'warning');
        }
      }else{
        this.message = 'Please Fill the Search Field and Try Again.';
        this.runToast('top-right', 'warning');
      }
      this.loading = false;
    },
    async getData(url = null){
      this.loading = true;
      let apiUrl='';
      if(url){
        apiUrl = 'jourlys/featured?'+url;
      }else{
        apiUrl = 'jourlys/featured';
      }
      let response =  await RequestService.get(apiUrl);
      if(response){
        this.showPagination = true;
        this.next_page = response.next_page.split('lastJourly=')[1];
        this.prev_page = response.prev_page.split('firstJourly=')[1];
        this.tableData = response.jourlys.original;
        for (let i = 0; i < this.tableData.length; i++) {
          if(this.tableData[i].isFeatured){
            this.tableData[i].isFeatured = 'true';
          }else{
            this.tableData[i].isFeatured = 'false';
          }

          if(this.tableData[i].isPreffered){
            this.tableData[i].isPreffered = 'true';
          }else{
            this.tableData[i].isPreffered = 'false';
          }
        }
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
      this.loading = false;
    
      // this.tableData = response;
    },
    handleEdit(index, row) {
      this.$router.push("/jourly/edit/"+row.id);
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              text: `You deleted ${row.name}`,
            });
          }
        });
    },
    async deleteRow(row) {
      await RequestService.delete('jourly/'+row.id+'/delete');
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      // const text =
      //   "Something went wrong. Please try again.";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    featuredJourly(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You are going to make this jourly featured.`,
          showCancelButton: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            this.changeRow(row,'featured');
            swalWithBootstrapButtons3.fire({
              title: "Featured!",
              text: `You have made '${row.name}' as Featured `,
            });
          }
        });
    },
    unfeaturedJourly(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You are going to remove this jourly from featured.`,
          showCancelButton: true,
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            this.changeRow(row,'not-featured');
            swalWithBootstrapButtons3.fire({
              title: "Unfeatured!",
              text: `You have unfeatured ${row.name}`,
            });
          }
        });
    },
    async changeRow(row,status) {
      await RequestService.get('jourly/'+row.id+'/'+status);
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
