<template>
  <div class="full-screen" v-if="!isMobile">    
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-12">
              <img id="profile-img" src="/img/brand/white.png" class="profile-img-card" style="max-width: 170px;min-width:100px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all">
        <h1 class="roboto" style="vertical-align: middle;color:#00fff2;margin-bottom:2rem" align="center">
          Jourly is currently only available on mobile devices. <br>
          Please re-visit this link from a smartphone.
        </h1>
        <h3 class="roboto" style="vertical-align: middle;color:white;margin-bottom:1rem" align="center">what is jourly?</h3>
        <router-link to="/" class="btn btn-primary roboto" style="width:200px;height:40px;border-radius: 0;background-color: #00fff2;background-image: linear-gradient(to right, #f7aaff , #00fff2);">VISIT OUR WEBSITE</router-link>
    </div>
  </div>
  <div class="full-screen" v-else>    
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-2">
            <div class="col-12" style="padding-left:40px">
              <img id="profile-img" src="/img/brand/white.png" class="profile-img-card" style="max-width: 100px;">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all-mobile" id="allMobile" style="display:none">
      <div class="row" @click="showModal">
        <div class="col-12" style="padding-left:0px;padding-right:0px;">
          <div class="banner-img-div">
            <img id="banner-img" src="/img/theme/journy.jpg" @load="imgLoad('Cover')" class="banner-img-card">
          </div>
          <div style="width: 100%;">
            <h1 class="roboto" style="vertical-align: middle;color:white;padding-top:10px;margin-bottom:0px;line-height:1.4" align="center">
              {{list.name}}
            </h1>
            <p class="roboto" style="vertical-align: middle;color:white;margin-bottom:10px;line-height:1.3" align="center">
              {{list.itemCount}} items
            </p>
          </div>
        </div>
        <div class="col-6" style="text-align:left;padding-left:12px;padding-top:6px;padding-bottom:0px;">
          <p style="color:white;margin-top:3px;margin-bottom:0px;">
          <img v-if="list.userImage!=''" id="rounded-img" :src="list.userImage" class="round-img-card" @load="imgLoad('User')" style="width: 35px;border-radius:50%">
            
            <span class="roboto" style="margin-left:5px" v-if="list.username!=''">@{{list.username}}</span></p>
        </div>
        <div class="col-6" style="text-align:right;padding-right:12px;padding-top:6px;padding-bottom:6px;">
          <button type="button" class="btn btn-dark btn-sm roboto" style="border: 1px solid white;margin-top:5px;border-radius: 0;background-color: transparent;">add as friend</button>
        </div>
      </div>
      <hr style="margin-top:.4rem;margin-bottom:12px;border: 1px solid #00fff2;margin-left: -15px;margin-right: -15px;">
      <div class="row" @click="showModal" v-if="list.privacy=='Public'" style="margin-bottom:150px">
        <div class="col-12" style="text-align:left;padding-left:6%">
          <div class="row" v-for="item in list.items" :key="item.name" style="margin-bottom:8px">
            <div class="col-2" style="max-width:43px;padding-bottom:10px">
              <i class="fa fa-check-square" v-if="item.checked" style="color: black;background-color: white;border-left: 2px solid white;border-right: 2px solid white;font-size: 30px;border-radius: 10%;" aria-hidden="true"></i>
            <i class="fa fa-check-square" v-else style="color: black;background-color: black;border: 2px solid white;font-size: 25px;border-radius: 10%;padding: 1px 3px 0px 0px;" aria-hidden="true"></i>
            </div>
            <div class="col-8">
              <label class="roboto">
                <span style="font-size: 20px;color: #00fff2;">{{item.name}}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <h1 style="color:#a891d6;font-size: calc(2rem + 0.45vw);" align="center"><i class="fas fa-lock"></i></h1>
          <h2 style="color:white;padding: 0px 30px 100px 30px;" align="center">This list is only visible to {{list.username}}'s friends</h2>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all-mobile" id="loadingMobile">
      <div class="row align-items-center py-2">
        <div class="col-12">
          <img src="/img/loading.gif" class="profile-img-card" style="max-width: 200px;margin-top:30%">
        </div>
      </div>
    </div>
    <div class="row" style="position: fixed;bottom: 0px;left: 0px;right: 0px;">
      <div class="col-12">
          <img src="/img/theme/app_footer.png" class="profile-img-card" style="max-width: 100%;">
      </div>
    </div>
    <share-list-modal v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>
<script>
import ShareListModal from './ShareListModal.vue'
import RequestService from '../../services/request.service';

export default {
  
  components: {
    ShareListModal
  },
  data() {
    return {
      listId : '',
      isMobile : false,
      displayModal: false,
      list:{
	      name: '',
	      username : '',
	      userImage : '',
        itemCount: 0,
        privacy: '',
        items: []
      },
      images:[],
      loading:true,
      countImageLoad:0,
      coverImgLoaded:false,
      userImgLoaded:false,
    };
  },
  mounted() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
    this.getData();
  },
  methods:{
     async getData(){
      this.listId = this.$route.params.id;
      let response =  await RequestService.get('list/'+this.listId+'/share-details');
      if(response.length<=0){
        alert("List Not Found!");
        return;
      }      
      this.list.name = response.name;
      this.list.username = response.username;
      this.list.itemCount = response.itemCount;
      this.list.items = response.items;
      this.list.privacy = response.privacy;
	    
      if(response.user){
        if(response.user.imagePath){
          this.list.userImage = response.user.imagePath;
          if(this.list.userImage==='' || this.list.userImage===null){
            this.userImgLoaded = true;
          }
        }else{
          this.userImgLoaded = true;
        }
      }else{
        this.userImgLoaded = true;
      }
    },
    showModal() {
        this.displayModal = true;
      },
    hideModal() {
      this.displayModal = false;
    },
    imgLoad(type){
      if(type==='Cover'){
        this.coverImgLoaded = true;
      }
      if(type==='User'){
        this.userImgLoaded = true;
      }
      if(this.coverImgLoaded){
        if(this.userImgLoaded){
          document.getElementById("loadingMobile").style.display='none';
          document.getElementById("allMobile").style.display='unset';
        }
      }
    }
  }
};
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Allura&family=Roboto:wght@300&display=swap');
  
  .allura {
    font-family:  'Allura', cursive;
    vertical-align: middle;
    font-size:30px;
    background: -webkit-linear-gradient(left, #f7aaff , #00fff2);
    background: -o-linear-gradient(right, #f7aaff, #00fff2);
    background: -moz-linear-gradient(right, #f7aaff, #00fff2);
    background: linear-gradient(to right, #f7aaff , #00fff2); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .blur-to-black{
    position:absolute;
    /* top:0; */
    left:0;
    /* width:250px; */
    /* height:250px;  */
    width:100%;
    /* height:100%; */
    -webkit-mask-image:-webkit-gradient(linear, top, bottom, from(rgba(0,0,0,.7)), to(rgba(0,0,0,0))); 
     mask-image: linear-gradient(to bottom, rgba(0,0,0,.7), rgba(0,0,0,0));
    /* -webkit-mask-image: linear-gradient(to left top, rgba(0,0,0,.2), rgba(0,0,0,.4)), linear-gradient(to left bottom, rgba(0,0,0,.2), rgba(0,0,0,.0));
    -webkit-mask-size: 100% 50%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position:  top,  bottom; */
     /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)); */
  }
  .full-screen {
    position: fixed;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    z-index: 0;
  }
  .all {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
    padding: 12%;
  }
  .all-mobile {
    overflow-y:auto;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
  }
  .modal {
  /* background: green; */
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
  .date-input{
    border-radius: 2rem;
    width: 50%;
    margin: 0px 25%;
    background-color: #00fff2;
    font-size: 1.2rem;
    color: white;
    padding: 0rem 0rem;
    text-align: center;
    height: calc(1em + .8rem);
  }
  .form-control:disabled{
    background-color: rgb(0, 255, 242);
    opacity: .9;
  }
  input:disabled {
      color: blueviolet;
      cursor: default;
  }
  .banner-img-div{
    width:100%;
    height:120px;
    overflow:hidden;
  }
  .banner-img-card{
    width: 100%;
    margin-top:-100px;
    position:relative;
  } 
  @media screen and (min-width: 450px) {
    .banner-img-div{
      height:145px;
    }
    .banner-img-card{
      margin-top:-105px;
    } 
  }
  @media screen and (min-width: 506px) {
    .banner-img-div{
      height:175px;
    }
    .banner-img-card{
      margin-top:-125px;
    } 
  }
  @media screen and (min-width: 600px) {
    .banner-img-div{
      height:195px;
    }
    .banner-img-card{
      margin-top:-140px;
    } 
  }
  @media screen and (min-width: 680px) {
    .banner-img-div{
      height:215px;
    }
    .banner-img-card{
      margin-top:-160px;
    } 
  }
   @media screen and (min-width: 770px) {
    .banner-img-div{
      height:240px;
    }
    .banner-img-card{
      margin-top:-180px;
    } 
  }
  @media screen and (min-width: 860px) {
    .banner-img-div{
      height:265px;
    }
    .banner-img-card{
      margin-top:-205px;
    } 
  }
  @media screen and (min-width: 980px) {
    .banner-img-div{
      height:315px;
    }
    .banner-img-card{
      margin-top:-230px;
    } 
  }
</style>
