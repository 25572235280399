<template>
   <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Edit List</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleEdit">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Name</label>
                  <div class="has-label">
                    <input
                      class="form-control"
                      name="name"
                      placeholder="Name"
                      v-model="list.name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">User ID</label>
                  <div class="has-label">
                    <input
                      class="form-control"
                      name="userId"
                      label="UserId"
                      placeholder="UserId"
                      v-model="list.userId"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Username</label>
                  <div class="has-label">
                    <input
                      class="form-control"
                      name="username"
                      label="Username"
                      placeholder="Username"
                      v-model="list.username"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Privacy</label>
                  <div class="has-label">
                    <input
                      class="form-control"
                      name="privacy"
                      label="Privacy"
                      placeholder="Privacy"
                      v-model="list.privacy"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-group">
                  <label class="form-control-label">ItemCount</label>
                  <div class="has-label">
                      <input
                        class="form-control"
                        name="itemCount"
                        label="ItemCount"
                        type="number"
                        placeholder="ItemCount"
                        v-model="list.itemCount"
                        disabled
                      />
                    </div>
                  </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-group">
                  <label class="form-control-label">ItemChecked</label>
                  <div class="has-label">
                      <input
                        class="form-control"
                        name="itemChecked"
                        label="ItemChecked"
                        type="number"
                        placeholder="ItemChecked"
                        v-model="list.itemChecked"
                        disabled
                      />
                    </div>
                  </div>
              </div>
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Update</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";


export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      listId : '',
      message : '',
      list:{
	      name: '',
	      userId : '',
	      username : '',
	      privacy : '',
	      itemCount : 0,
	      itemChecked : 0,
      }
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(){
      let route = useRoute();
      this.listId = route.params.id;
      let response =  await RequestService.get('list/'+this.listId+'/show');
      console.log(response);
      this.list.name = response.name;
      this.list.userId = response.userId;
      this.list.privacy = response.privacy;
      this.list.username = response.username;
      this.list.itemCount = response.itemCount;
      this.list.itemChecked = response.itemChecked;
    },
    async handleEdit() {
      let response =  await RequestService.put('list/'+this.listId+'/update',this.list);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/list/index");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
