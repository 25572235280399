<template>
   <div class="content" style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div v-if="!loading">
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Reports</h3>
            <p class="text-sm mb-0" v-if="showPagination">
              List of all reports.
            </p>
            <p class="text-sm mb-0" v-else>
              Reports Filtered by {{searchColumn}} = '{{searchQuery}}'
            </p>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="row" style="margin-left:auto;margin-bottom:5px">
                <div class="col-md-3">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="searchColumn"
                    placeholder="Select Column"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in tableColumnNames"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-md-4" v-if="searchColumn=='status'">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="searchQuery"
                    placeholder="Select Status"
                  >
                    <el-option
                      class="select-primary"
                      label="Pending"
                      value="pending"
                    >
                    </el-option>
                    <el-option
                      class="select-primary"
                      label="Archive"
                      value="archive"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-md-4" v-else>
                  <div class="el-input  el-input--prefix el-input--suffix mb-3" style="width: 100%;">
                    <input class="el-input__inner" type="search" autocomplete="off" tabindex="" aria-label="" v-model="searchQuery" placeholder="Search records">
                    <span class="el-input__prefix"><i class="el-input__icon el-icon-search"></i></span>
                  </div>
                </div>
                <div class="col-md-5">
                  <button type="button" class="btn base-button btn-info btn-md" @click.stop="onSearch">Search</button>
                  <button type="button" class="btn base-button btn-primary btn-md" v-if="!showPagination" @click.stop="getData">Back to Index</button>
                </div>
              </div>
            </div>
            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column label="Reported Item Link">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <button type="button" style="color:white" @click="handleDetails(props.$index, props.row)" class="btn base-button btn-icon btn-fab btn-primary btn-sm info btn-link">
                      Click to Open
                    </button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Explanation">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <button type="button" style="color:white" @click="handleExplanationModal(props.$index, props.row)" class="btn base-button btn-icon btn-fab btn-info btn-sm info btn-link">
                      View
                    </button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <el-tooltip placement="top" content="Delete Reported Item">
                      <button type="button" @click="handleDeleteItem(props.$index, props.row)" class="btn btn-warning btn-sm">
                        Delete
                      </button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Dismiss">
                      <base-button
                        @click="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="text-white ni ni-fat-remove"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  <!-- Showing {{ from + 1 }} to {{ to }} of {{ total }} entries -->
                </p>
              </div>
              <ul class="pagination pagination-primary pagination-no-border" v-if="showPagination">
                <li class="page-item prev-page">
                  <a class="page-link" aria-label="Previous" @click="getData('firstReport='+prev_page)">
                    <i class="fa fa-angle-left"></i>
                  </a>
                </li>
                <li class="page-item active"><a class="page-link"><i class="fa fa-pause"></i></a></li>
                <li class="page-item page-pre next-page">
                  <a class="page-link" aria-label="Next" @click="getData('lastReport='+next_page)"><i class="fa fa-angle-right"></i></a>
                </li>
              </ul>
              <!-- <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination> -->
            </div>
          </template>
        </card>
        <modal v-model:show="showExplanationModal">
          <template v-slot:header>
              <h5 class="modal-title" id="exampleModalLabel">Explanation</h5>
          </template>
          <div>
            {{explanationModalMessage}}
          </div>
          <template v-slot:footer>
              <base-button type="secondary" @click="showExplanationModal = false">Close</base-button>
              <!-- <base-button type="primary">Save changes</base-button> -->
          </template>
        </modal>
      </div>
      <div v-else style="text-align:center;padding:100px">
        <img src="/img/loading.gif"/>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Modal from "@/components/Modal";
// import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import RequestService from '../../services/request.service';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  components: {
    // BasePagination,
    RouteBreadCrumb,
    Modal,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    // pagedData() {
    //   return this.tableData.slice(this.from, this.to);
    // },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    // queriedData() {
    //   if (!this.searchQuery) {
    //     return this.pagedData;
    //   }
    //   let result = this.tableData.filter((row) => {
    //     let isIncluded = false;
    //     for (let key of this.propsToSearch) {
    //       let rowValue = row[key].toString();
    //       if (rowValue.includes && rowValue.includes(this.searchQuery)) {
    //         isIncluded = true;
    //       }
    //     }
    //     return isIncluded;
    //   });

    //   return result.slice(this.from, this.to);
    // },
    // to() {
    //   let highBound = this.from + this.pagination.perPage;
    //   if (this.total < highBound) {
    //     highBound = this.total;
    //   }
    //   return highBound;
    // },
    // from() {
    //   return this.pagination.perPage * (this.pagination.currentPage - 1);
    // },
    // total() {
    //   return this.searchedData.length > 0
    //     ? this.searchedData.length
    //     : this.tableData.length;
    // },
  },
  data() {
    return {
      showExplanationModal: false,
      explanationModalMessage: "",
      message: "",
      // pagination: {
      //   perPage: 5,
      //   currentPage: 1,
      //   perPageOptions: [5, 10, 25, 50],
      //   total: 0,
      // },
      searchQuery: "",
      searchColumn: "",
      tableColumnNames: ["id","reported_item_id","report_reason","reported_item_type","reporter_id","status"],
      propsToSearch: ["id","reported_item_id","report_reason","reported_item_type","reporter_id","status"],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          // minWidth: 200,
        },
        {
          prop: "report_reason",
          label: "Report Reason",
          // minWidth: 200,
        },
        {
          prop: "reported_item_type",
          label: "Reported Item Type",
          // minWidth: 200,
        },{
          prop: "reporter_id",
          label: "Reporter ID",
          // minWidth: 200,
        },{
          prop: "status",
          label: "Status",
          // minWidth: 200,
        },
        // {
        //   prop: "reported_item_id",
        //   label: "Reported item ID",
        //   // minWidth: 200,
        // },
      ],
      tableData: [],
      next_page:'',
      prev_page:'',
      fuseSearch: null,
      searchedData: [],
      showPagination : true,
      loading:true
    };
  },
  mounted(){
    ///jourlys?firstJourly=Test Jourly
    this.getData();
    
  },
  methods: {
    async onSearch(){
      this.loading = true;
      if(this.searchQuery!=''){
        if(this.searchColumn!=''){
          this.showPagination = false;
          let apiUrl = 'report/search?search='+this.searchQuery+'&column='+this.searchColumn;
          let response =  await RequestService.get(apiUrl);
          console.log(response);
          if(response){
            this.tableData = response;
          }else{
            this.message = 'Something went worng. Please try again.';
            this.runToast('top-right', 'warning');
          }  
        }else{
          this.message = 'Please Select the Search Column and Try Again.';
          this.runToast('top-right', 'warning');
        }
      }else{
        this.message = 'Please Fill the Search Field and Try Again.';
        this.runToast('top-right', 'warning');
      }
      this.loading = false;
    },
    async getData(url = null){
      this.loading = true;
      let apiUrl='';
      if(url){
        apiUrl = 'reports?'+url;
      }else{
        apiUrl = 'reports';
      }
      let response =  await RequestService.get(apiUrl);
      if(response){
        this.showPagination = true;
        this.searchQuery = '';
        this.searchColumn = '';
        this.next_page = response.next_page.split('lastReport=')[1];
        this.prev_page = response.prev_page.split('firstReport=')[1];
        this.tableData = response.reports.original;
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
      this.loading = false; 
      // this.tableData = response;
    },
    handleDetails(index, row) {
      if(row.reported_item_type=='jourly'){
        this.$router.push("/jourly/details/"+row.reported_item_id);
      }else if(row.reported_item_type=='story'){
        this.$router.push("/story/details/"+row.reported_item_id);
      }else if(row.reported_item_type=='user'){
        this.$router.push("/jourly-user/edit/"+row.reported_item_id);
      }else if(row.reported_item_type=='list'){
        this.$router.push("/list/edit/"+row.reported_item_id);
      }else if(row.reported_item_type=='advertisement'){
        this.$router.push("/advertisement/edit/"+row.reported_item_id);
      }else if(row.reported_item_type=='comment'){
        this.$router.push("/jourly/"+row.reported_parent_item_id+"/comments");
      }else{
        alert('Only Jourly and story Details available. Other type is not available yet.')
      }
    },
    handleDeleteItem(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            if(this.deleteReportedItem(row)){
              swalWithBootstrapButtons3.fire({
                title: "Deleted!",
                text: `You deleted ${row.reported_item_type} ${row.reported_item_id}`,
              });
            }
          }
        });
    },
    async deleteReportedItem(row) {
      let url = "";
      if(row.reported_item_type=='jourly'){
        url = "jourly/" + row.reported_item_id + "/delete";
      }else if(row.reported_item_type=='story'){
        url = "story/" + row.reported_item_id + "/delete";
      }else if(row.reported_item_type=='user'){
        url = "jourly-user/" + row.reported_item_id + "/delete";
      }else if(row.reported_item_type=='list'){
        url = "list/" + row.reported_item_id + "/delete";
      }else if(row.reported_item_type=='advertisement'){
        url = "advertisement/" + row.reported_item_id + "/delete"
      }else if(row.reported_item_type=='comment'){
        // url = "jouly/"+ row.reported_parent_item_id + "/comment/" + row.reported_item_id + "/delete"
        url = "jourly/"+row.reported_parent_item_id+"/comment/"+ row.reported_item_id +"/delete";
      }else{
        alert('Only Jourly and story Details available. Other type is not available yet.');
        return false;
      }
      await RequestService.delete(url);
      return true;
    },
    handleExplanationModal(index, row) {
      this.explanationModalMessage = row.explanation;
      this.showExplanationModal = true;
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              text: `You deleted ${row.id}`,
            });
          }
        });
    },
    async deleteRow(row) {
      await RequestService.delete('report/'+row.id+'/delete');
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      // const text =
      //   "Something went wrong. Please try again.";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
