<template>
  <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Create New Jourly User</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleCreate">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <base-input
                  name="bio"
                  label="Bio"
                  placeholder="Bio"
                  required
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <base-input
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  required
                />
              </div>
              <div class="col-md-12 col-sm-12">
                <base-input
                  name="imagePath"
                  label="ImagePath"
                  placeholder="ImagePath"
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <base-input
                  name="username"
                  label="Username"
                  placeholder="Username"
                  required
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <base-input
                  name="website"
                  label="Website"
                  type="text"
                  placeholder="Website"
                />
              </div>
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Create</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      jourlyuser:{
	      bio: '',
	      email : '',
	      imagePath : '',
	      website : '',
	      username : '',
      }
    };
  },
  methods: {
    async handleCreate(jourlyUser) {
      jourlyUser.status = 'regular';
      let response =  await RequestService.post('jourly-user/store',jourlyUser);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/jourly-user/index");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
