<template>
  <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Edit Jourly User</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleEdit">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Bio <span>*</span></label>
                  <div class="has-label">
                    <input class="form-control" name="bio" id="bio" type="text" placeholder="Bio" v-model="jourlyUser.bio" required>
                  </div>
                </div>
                <!-- <base-input
                  name="name"
                  label="Name"
                  v-model="jourly.name"
                  required
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Email</label>
                  <div class="has-label">
                    <input class="form-control" name="email" id="email" type="email" placeholder="Email" v-model="jourlyUser.email" required>
                  </div>
                </div>
                <!-- <base-input
                  name="date"
                  label="Date"
                  placeholder="Date"
                  type="date"
                /> -->
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">ImagePath</label>
                  <div class="has-label">
                    <input class="form-control" name="imagePath" id="imagePath" type="text" placeholder="ImagePath" v-model="jourlyUser.imagePath">
                  </div>
                </div>
                <!-- <base-input
                  name="imagePath"
                  label="ImagePath"
                  placeholder="ImagePath"
                  v-model="jourly.imagePath"
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Username</label>
                  <div class="has-label">
                    <input class="form-control" name="username" id="username" type="text" placeholder="Username" v-model="jourlyUser.username" required>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Website</label>
                  <div class="has-label">
                    <input class="form-control" name="website" id="website" type="text" placeholder="Website" v-model="jourlyUser.website" required>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group" style="margin-bottom: .5rem;">
                  <label class="form-control-label">Is Banned</label>
                </div>
                <base-checkbox name="isBanned" v-model="isBanned" class="mb-3"></base-checkbox>
              </div>
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Update</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";


export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      jourlyUserId : '',
      message : '',
      isBanned: false,
      jourlyUser:{
	      bio: '',
	      email : '',
	      imagePath : '',
	      username : '',
	      website : '',
	      status : 'regular',
      }
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(){
      let route = useRoute();
      this.jourlyUserId = route.params.id;
      let response =  await RequestService.get('jourly-user/'+this.jourlyUserId+'/show');
      this.jourlyUser.bio = response.bio;
      this.jourlyUser.email = response.email;
      this.jourlyUser.imagePath = response.imagePath;
      this.jourlyUser.username = response.username;
      this.jourlyUser.website = response.website;
      this.jourlyUser.status = response.status;
      if(this.jourlyUser.status=='banned'){
        this.isBanned = true;
      }
    },
    async handleEdit() {
      if(this.isBanned){
        this.jourlyUser.status = 'banned';
      }else{
        this.jourlyUser.status = 'regular';
      }
      let response =  await RequestService.put('jourly-user/'+this.jourlyUserId+'/update',this.jourlyUser);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/jourly-user/index");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
