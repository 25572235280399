<template>
  <div>
    <div class="header header-top" style="height:90vh">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Dashboard</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
              <!-- <base-button size="sm" type="neutral">New</base-button> -->
              <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
            </div>
          </div>

          <!-- Card stats -->
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <stats-card
                title="Total Users"
                type="gradient-red"
                :sub-title="userCount"
                icon="fa fa-users"
              >
                <template v-slot:footer>
                  <!-- <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 3.48%</span
                  > -->
                  <span class="text-success mr-2">Private: {{privateUserCount}}</span>
                  <span class="text-success">Public: {{publicUserCount}}</span>
                  <!-- <span class="text-nowrap">Since last month</span> -->
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-6">
              <stats-card
                title="Total Jourlys"
                type="gradient-orange"
                :sub-title="jourlyCount"
                icon="ni ni-bus-front-12"
              >
                <template v-slot:footer>
                  <span class="text-success mr-2">Private: {{privateJourlyCount}}</span>
                  <span class="text-primary mr-2">Public: {{publicJourlyCount}}</span>
                  <span class="text-info">Friend: {{friendJourlyCount}}</span>
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-6">
              <stats-card
                title="Active Users(7days)"
                type="gradient-orange"
                :sub-title="active7daysUsers"
                icon="ni ni-chart-bar-32"
              >
                <template v-slot:footer>
                  <!-- <span class="text-success mr-2">Private: {{privateJourlyCount}}</span> -->
                  <span class="text-primary mr-2">Active Users(30 days): {{active30daysUsers}}</span><br>
                  <span class="text-info">Screen Page Views: {{screenPageViews}}</span>
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-6">
              <stats-card
                title="Total Storage Used"
                type="gradient-green"
                :sub-title="storageSize+'MB'"
                icon="ni ni-money-coins"
              >
                <!-- <template v-slot:footer>
                  <span class="text-danger mr-2"
                    ><i class="fa fa-arrow-down"></i> 111</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </template> -->
                <div v-if="storageLoading" style="text-align:center;padding:10px">
                  <img src="/img/loading.gif" style="width:80px"/>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-6">
              <stats-card
                title="Total Stories"
                type="gradient-green"
                :sub-title="storyCount"
                icon="ni ni-album-2"
              >
                <!-- <template v-slot:footer>
                  <span class="text-danger mr-2"
                    ><i class="fa fa-arrow-down"></i> 111</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </template> -->
              </stats-card>
            </div>
            <!-- <div class="col-xl-3 col-md-6">
              <stats-card
                title="Performance"
                type="gradient-info"
                sub-title="49,65%"
                icon="ni ni-chart-bar-32"
              >
                <template v-slot:footer>
                  <span class="text-success mr-2"
                    ><i class="fa fa-arrow-up"></i> 54.8%</span
                  >
                  <span class="text-nowrap">Since last month</span>
                </template>
              </stats-card>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <base-header class="pb-6 header-top" style="background-color:transparent">
      
    </base-header> -->

    <div class="container-fluid">
      <!--Widgets-->
      <div class="row">
        <div class="col-xl-5">
          
        </div>
      </div>
      <!--End Widgets-->
    </div>
  </div>
</template>

<script>
// import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import RequestService from "../../services/request.service";

export default {
  // components: {
  //   RouteBreadCrumb,
  // },
  data() {
    return {
      userCount: 0,
      publicUserCount: 0,
      privateUserCount: 0,
      jourlyCount: 0,
      friendJourlyCount: 0,
      publicJourlyCount: 0,
      privateJourlyCount: 0,
      storyCount: 0,
      storageSize: 0,
      storageLoading: true,
      active7daysUsers: 0,
      active30daysUsers: 0,
      screenPageViews: 0
    };
  },
  methods: {
    async getData() {
      let response = await RequestService.get("dashboard-info");
      if (response) {
        this.userCount = response.userCount;
        this.publicUserCount = response.publicUserCount;
        this.privateUserCount = response.privateUserCount;
        this.jourlyCount = response.jourlyCount;
        this.privateJourlyCount = response.privateJourlyCount;
        this.publicJourlyCount = response.publicJourlyCount;
        this.friendJourlyCount = response.friendJourlyCount;
        this.storyCount = response.storyCount;
        this.active7daysUsers = response.analyticsInfo.active7daysUsers;
        this.active30daysUsers = response.analyticsInfo.active30daysUsers;
        this.screenPageViews = response.analyticsInfo.screenPageViews;
      }
      this.getStorageData();
    },
    async getStorageData() {
      let response = await RequestService.get("storage-info");
      if (response) {
        this.storageSize = response.size;
      }
      this.storageLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.text-white {
  color: transparent;
}
.fa-home {
  color: transparent;
}
.bg-success {
  background-color: transparent;
}
</style>
