<template>
  <div class="full-screen" v-if="!isMobile">    
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-12">
              <img id="profile-img" src="/img/brand/white.png" class="profile-img-card" style="max-width: 170px;min-width:100px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all">
        <h1 style="vertical-align: middle;color:white;margin-bottom:2rem" align="center">
          Jourly is currently only available on mobile devices. <br>
          Please re-visit this link from a smartphone.
        </h1>
        <h3 style="vertical-align: middle;color:white;margin-bottom:1rem" align="center">what is jourly?</h3>
        <router-link to="/" class="btn btn-primary">VISIT OUR WEBSITE</router-link>
    </div>
  </div>
  <div class="full-screen" v-else>    
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-2">
            <div class="col-12">
              <img id="profile-img" src="/img/brand/white.png" class="profile-img-card" style="max-width: 100px;">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6 all-mobile">
      <div class="row" @click="showModal">
        <div class="col-12" style="padding-left:0px;padding-right:0px;">
          <img id="banner-img" src="/img/theme/img-1-1000x600.jpg" class="banner-img-card" style="width: 100%;position:relative">
          <div style="position:absolute;width: 100%;margin-top: -40%;">
              <h1 style="vertical-align: middle;color:white;" align="center">
              Trip to Cox's Bazar
            </h1>
            <input disabled type="text" style="border-radius: 2rem;width: 50%;margin: 0 25%;" class="form-control form-rounded" placeholder="">
          </div>
        </div>
        <div class="col-6" style="text-align:left">
          <p style="color:white;margin-top:3px">
          <img id="rounded-img" src="/img/theme/team-1.jpg" class="round-img-card" style="width: 35px;border-radius:50%">
            
            @jhpolash</p>
        </div>
        <div class="col-6" style="text-align:right">
          <button type="button" class="btn btn-dark btn-sm" style="border: 1px solid white;margin-top:5px">add as friend</button>
        </div>
        <div class="col-12">
          <h1 class="carattere" style="vertical-align: middle;line-leight:1.2" align="center">
            Jourly Stories
          </h1>
          <h3 style="color:white">stories that are shared to the world</h3>
        </div>
      </div>
      <hr style="margin-top:1rem;margin:bottom:1rem;border: 1px solid #c7a4a4;margin-left: -15px;margin-right: -15px;">
      <div class="row" @click="showModal">
        <div class="col-12">
          <h2 style="color:white;" align="center">Kolatoli Beach</h2>
        </div>
        <div class="col-6" style="margin:0px;padding:0px">
          <img src="/img/theme/team-1.jpg" class="round-img-card" style="width:100%;height:100%">
        </div>
        <div class="col-6" style="margin:0px;padding:0px">
          <img src="/img/theme/team-4.jpg" class="round-img-card" style="width:100%;height:100%">
        </div>
        <div class="col-6" style="margin:0px;padding:0px;background-color:#ffffffb8">
          <img src="/img/theme/team-1.jpg" class="round-img-card" style="width:100%;height:100%;opacity:.3">
        </div>
        <div class="col-6" style="margin:0px;padding:0px;background-color:#ffffffb8">
          <img src="/img/theme/team-4.jpg" class="round-img-card" style="width:100%;height:100%;opacity:.3">
        </div>
      </div>
    </div>
    <message-modal v-if="displayModal" @close-modal-event="hideModal" />
  </div>
</template>
<script>
import MessageModal from './MessageModal.vue'
export default {
  
  components: {
    MessageModal
  },
  data() {
    return {
     isMobile : false,
     displayModal: false 
    };
  },
  mounted() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
  },
  methods:{
    showModal() {
        this.displayModal = true;
      },
      hideModal() {
        this.displayModal = false;
      }
  }
};
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Carattere');
  
  .carattere {
    font-family: 'Carattere', sans-serif;
    color:#c7a4a4;
    font-size: 2.3rem;
  }
  .full-screen {
    position: fixed;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    z-index: 0;
  }
  .all {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
    padding: 12%;
  }
  .all-mobile {
    overflow-y:auto;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
  }
  .modal {
  /* background: green; */
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
