import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

//Auth Template pages
import Home from "../components/Home.vue";
import LoginA from "../components/Login.vue";
import RegisterA from "../components/Register.vue";
// lazy-loaded
// const ProfileA = () => import("../components/Profile.vue")
const BoardAdmin = () => import("../components/BoardAdmin.vue")
const BoardModerator = () => import("../components/BoardModerator.vue")
const BoardUser = () => import("../components/BoardUser.vue")
//end


// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Alternative from "../views/Dashboard/AlternativeDashboard.vue";
import Widgets from "../views/Widgets.vue";
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";
import Message from "../views/Message.vue";

// Pages
import Pricing from "../views/Pages/Pricing.vue";
import Login from "../views/Pages/Login.vue";
import Register from "../views/Pages/Register.vue";
import Lock from "../views/Pages/Lock.vue";
import Profile from "../views/Pages/UserProfile.vue";
import Timeline from "../views/Pages/TimeLinePage.vue";

// Components pages
import Buttons from "../views/Components/Buttons.vue";
import Cards from "../views/Components/Cards.vue";
import Grid from "../views/Components/GridSystem.vue";
import Notifications from "../views/Components/Notifications.vue";
import Icons from "../views/Components/Icons.vue";
import Typography from "../views/Components/Typography.vue";

//Jourly Routes
import JourlyIndex from "../views/Jourly/Index.vue";
import JourlyIsFeaturedIndex from "../views/Jourly/IsFeaturedIndex.vue";
import JourlyCreate from "../views/Jourly/Create.vue";
import JourlyEdit from "../views/Jourly/Edit.vue";
import JourlyDetails from "../views/Jourly/Details.vue";
import JourlyCommentIndex from "../views/Jourly/CommentIndex.vue";
import ShareJourly from "../views/Jourly/ShareJourly.vue";
import JourlyCollaboratorIndex from "../views/Jourly/CollaboratorIndex.vue";
import JourlyCollaboratorEdit from "../views/Jourly/EditCollaborator.vue";
//Jourly User Routes
import JourlyUserIndex from "../views/JourlyUser/Index.vue";
import JourlyBannedUserIndex from "../views/JourlyUser/BannedUserIndex.vue";
import JourlyUserCreate from "../views/JourlyUser/Create.vue";
import JourlyUserEdit from "../views/JourlyUser/Edit.vue";
//Story
import StoryIndex from "../views/Story/Index.vue";
import StoryEdit from "../views/Story/Edit.vue";
import StoryCreate from "../views/Story/Create.vue";
import StoryDetails from "../views/Story/Details.vue";
import ShareList from "../views/List/ShareList.vue";
//List Routes
import ListIndex from "../views/List/Index.vue";
import ListCreate from "../views/List/Create.vue";
import ListEdit from "../views/List/Edit.vue";
import ListItemIndex from "../views/List/Item/Index.vue";
import ListItemCreate from "../views/List/Item/Create.vue";
import ListItemEdit from "../views/List/Item/Edit.vue";
import ListItemCollaborationIndex from "../views/List/Item/CollaborationIndex.vue";
import ListItemCollaborationEdit from "../views/List/Item/CollaborationEdit.vue";
import ListCollaboratorIndex from "../views/List/CollaboratorIndex.vue";
import ListCollaboratorEdit from "../views/List/EditCollaborator.vue";
//Advertisement
import AdvertisementIndex from "../views/Advertisement/Index.vue";
import AdvertisementCreate from "../views/Advertisement/Create.vue";
import AdvertisementEdit from "../views/Advertisement/Edit.vue";

import ReportIndex from "../views/Report/Index.vue";
import ResetPassword from "../views/Setting/ResetPassword.vue";


// Tables pages
import RegularTables from "../views/Tables/RegularTables.vue";
import SortableTables from "../views/Tables/SortableTables.vue";
import PaginatedTables from "../views/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "../views/Maps/GoogleMaps.vue";
import VectorMaps from "../views/Maps/VectorMaps.vue";

// Forms pages
import FormElements from "../views/Forms/FormElements.vue";
import FormComponents from "../views/Forms/FormComponents.vue";
import FormValidation from "../views/Forms/FormValidation.vue";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
    },
    {
      path: "grid-system",
      name: "Grid",
      components: { default: Grid },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      components: { default: RegularTables },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
    },
  ],
};

let jourlyMenu = {
  path: "/jourly",
  component: DashboardLayout,
  redirect: "/jourly/index",
  name: "Jourlys",
  children: [
    {
      path: "index",
      name: "Jourlys",
      components: { default: JourlyIndex },
    },
    {
      path: "featured-index",
      name: "Featured Jourlys",
      components: { default: JourlyIsFeaturedIndex },
    },
    {
      path: "create",
      name: "Jourly Create",
      components: { default: JourlyCreate },
    },
    {
      path: "edit/:id",
      name: "Jourly Edit",
      components: { default: JourlyEdit },
    },
    {
      path: "details/:id",
      name: "Jourly Details",
      components: { default: JourlyDetails },
    },
    {
      path: ":id/collaborators",
      name: "Jourly Collaborators",
      components: { default: JourlyCollaboratorIndex },
    },
    {
      path: ":id/collaborator/edit/:collaboratorId",
      name: "Change Jourly Collaborator",
      components: { default: JourlyCollaboratorEdit },
    },
    {
      path: ":id/comments",
      name: "Jourly Comments",
      components: { default: JourlyCommentIndex },
    },
  ],
};
let storyMenu = {
  path: "/story",
  component: DashboardLayout,
  redirect: "/story/index",
  name: "Stories",
  children: [
    {
      path: "index",
      name: "Stories",
      components: { default: StoryIndex },
    },
    {
      path: "create",
      name: "Story Create",
      components: { default: StoryCreate },
    },
    {
      path: "edit/:id",
      name: "Story Edit",
      components: { default: StoryEdit },
    },
    {
      path: "details/:id",
      name: "Story Details",
      components: { default: StoryDetails },
    },
  ],
};
let jourlyUserMenu = {
  path: "/jourly-user",
  component: DashboardLayout,
  redirect: "/jourly-user/index",
  name: "JourlyUsers",
  children: [
    {
      path: "index",
      name: "Jourly Users",
      components: { default: JourlyUserIndex },
    },
    {
      path: "create",
      name: "Jourly User Create",
      components: { default: JourlyUserCreate },
    },
    {
      path: "edit/:id",
      name: "Jourly User Edit",
      components: { default: JourlyUserEdit },
    },
    {
      path: "banned-index",
      name: "Jourly Banned Users",
      components: { default: JourlyBannedUserIndex },
    },
  ],
};

let listMenu = {
  path: "/list",
  component: DashboardLayout,
  redirect: "/list/index",
  name: "Lists",
  children: [
    {
      path: "index",
      name: "Lists",
      components: { default: ListIndex },
    },
    {
      path: "create",
      name: "List Create",
      components: { default: ListCreate },
    },
    {
      path: "edit/:id",
      name: "List Edit",
      components: { default: ListEdit },
    },
    {
      path: ":id/items",
      name: "List Items",
      components: { default: ListItemIndex },
    },
    {
      path: ":id/item/create",
      name: "List Item Create",
      components: { default: ListItemCreate },
    },
    {
      path: ":id/item/edit/:itemId",
      name: "List Item Edit",
      components: { default: ListItemEdit },
    },
    {
      path: ":listId/item/:listItemId/collaborations",
      name: "List Item Collaborations",
      components: { default: ListItemCollaborationIndex },
    },
    {
      path: ":listId/item/:listItemId/collaboration/:collaborationId/edit",
      name: "List Item Collaboration Edit",
      components: { default: ListItemCollaborationEdit },
    },
    {
      path: ":id/collaborators",
      name: "List Collaborators",
      components: { default: ListCollaboratorIndex },
    },
    {
      path: ":id/collaborator/edit/:collaboratorId",
      name: "Change List Collaborator",
      components: { default: ListCollaboratorEdit },
    },
  ],
};

let advertisementMenu = {
  path: "/advertisement",
  component: DashboardLayout,
  redirect: "/advertisement/index",
  name: "Advertisements",
  children: [
    {
      path: "index",
      name: "Advertisements",
      components: { default: AdvertisementIndex },
    },
    {
      path: "create",
      name: "Advertisement Create",
      components: { default: AdvertisementCreate },
    },
    {
      path: "edit/:id",
      name: "Advertisement Edit",
      components: { default: AdvertisementEdit },
    },
  ],
};

let settingMenu = {
  path: "/setting",
  component: DashboardLayout,
  redirect: "/dashboard",
  name: "Setting",
  children: [
    {
      path: "reset-password",
      name: "ResetPassword",
      components: { default: ResetPassword },
    },
  ],
};

let reportMenu = {
  path: "/report",
  component: DashboardLayout,
  redirect: "/report/index",
  name: "Reports",
  children: [
    {
      path: "index",
      name: "Reports",
      components: { default: ReportIndex },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "vector",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Forms elements",
      components: { default: FormElements },
    },
    {
      path: "components",
      name: "Forms Components",
      components: { default: FormComponents },
    },
    {
      path: "validation",
      name: "Forms Validation",
      components: { default: FormValidation },
    },
  ],
};

const routes = [
  // Auth t routes
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login-a",
    component: LoginA,
  },
  {
    path: "/register-a",
    component: RegisterA,
  },
  // {
  //   path: "/profile-a",
  //   name: "profile",
  //   // lazy-loaded
  //   component: ProfileA,
  // },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  // End
  {
    path: "/",
    redirect: "/login",
    component: DashboardLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/alternative",
        name: "Alternative",
        components: { default: Alternative },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "/calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "/pages/user",
        name: "Profile",
        components: { default: Profile },
      },
      {
        path: "/pages/timeline",
        name: "Timeline",
        components: { default: Timeline },
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/pricing",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "Register",
        components: { default: Register },
      },
      {
        path: "/lock",
        name: "Lock",
        components: { default: Lock },
      },
    ],
  },
  {
    path: "/message",
    name: "Message",
    component: Message,
  },
  {
    path: "/share/jourly/:id",
    name: "Share Jourly",
    component: ShareJourly,
  },
  {
    path: "/share/list/:id",
    name: "Share List",
    component: ShareList,
  },
  componentsMenu,
  tablesMenu,
  mapsMenu,
  formsMenu,
  jourlyMenu,
  jourlyUserMenu,
  storyMenu,
  listMenu,
  advertisementMenu,
  reportMenu,
  settingMenu,
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});
router.beforeEach((to, from, next) => {
  let id = to.params.id;
  const publicPages = ['/login', '/register','/message','/share/jourly/'+id,'/share/list/'+id];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
