<template>
   <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Details Story</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-6 col-sm-12" v-for="(value, name, index) in story" :key="index">
              <div class="form-group">
                <label class="form-control-label" style="text-transform: capitalize" v-if="name=='imagePath'">Image</label>
                <label class="form-control-label" style="text-transform: capitalize" v-else>{{name}}</label>
                <div class="has-label">
                  <img v-if="name=='imagePath'" :src="value" alt="Story Image" width="200">
                  <p v-else>{{value}}</p>
                  <!-- <input class="form-control"  type="text" :value="value" disabled> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useRoute } from 'vue-router';
// import { useToast } from "vue-toastification";
// import Notification from "@/components/Notification";


export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      storyId : '',
      message : '',
      story:[]
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(){
      let route = useRoute();
      this.storyId = route.params.id;
      let response =  await RequestService.get('story/'+this.storyId+'/details');
      this.story = response;
      console.log(response)
    },
  },
};
</script>
