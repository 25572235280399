<template>
  <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Edit Jourly</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleEdit">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Name <span>*</span></label>
                  <div class="has-label">
                    <input class="form-control" name="name" id="name" type="text" placeholder="Name" v-model="jourly.name" required>
                  </div>
                </div>
                <!-- <base-input
                  name="name"
                  label="Name"
                  v-model="jourly.name"
                  required
                /> -->
              </div>
              <!-- <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Date</label>
                  <div class="has-label">
                    <input class="form-control" name="date" id="date" type="date" placeholder="Date" v-model="jourly.date">
                  </div>
                </div> -->
                <!-- <base-input
                  name="date"
                  label="Date"
                  placeholder="Date"
                  type="date"
                /> -->
              <!-- </div> -->
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">ImagePath</label>
                  <div class="has-label">
                    <input class="form-control" name="imagePath" id="imagePath" type="text" placeholder="ImagePath" v-model="jourly.imagePath">
                  </div>
                </div>
                <!-- <base-input
                  name="imagePath"
                  label="ImagePath"
                  placeholder="ImagePath"
                  v-model="jourly.imagePath"
                /> -->
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">User ID</label>
                  <div class="has-label">
                    <input class="form-control" name="userId" id="userId" type="text" placeholder="UserID" required v-model="jourly.userId">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Story Count</label>
                  <div class="has-label">
                    <input class="form-control" name="storyCount" id="storyCount" type="number" placeholder="Story Count" v-model="jourly.storyCount">
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group" style="margin-bottom: .5rem;">
                  <label class="form-control-label">IsPreffered</label>
                </div>
                <base-checkbox name="isPreffered" v-model="jourly.isPreffered" class="mb-3"></base-checkbox>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group" style="margin-bottom: .5rem;">
                  <label class="form-control-label">IsFeatured</label>
                </div>
                <base-checkbox name="isFeatured" v-model="jourly.isFeatured" class="mb-3"></base-checkbox>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-group" style="margin-bottom: .5rem;">
                  <label class="form-control-label">Awarded</label>
                </div>
                <base-checkbox name="awarded" v-model="jourly.awarded" class="mb-3"></base-checkbox>
              </div>

              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Award Text</label>
                  <div class="has-label">
                    <input class="form-control" name="awardText" id="awardText" type="text" placeholder="Award Text" v-model="jourly.awardText">
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Update</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";


export default {
  name: "form-elements",
  // components: {
  //   RouteBreadCrumb,
  //   // Form,
  // },
  data() {
    return {
      jourlyId : '',
      message : '',
      jourly:{
	      name: '',
	      date : '',
	      imagePath : '',
	      isPreffered : false,
	      isFeatured : false,
        awarded: false,
        awardText: '',
	      storyCount : 0,
	      userId: ''
      }
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(){
      let route = useRoute();
      this.jourlyId = route.params.id;
      let response =  await RequestService.get('jourly/'+this.jourlyId+'/show');
      this.jourly.name = response.name;
      this.jourly.date = response.date;
      this.jourly.awarded = response.awarded;
      this.jourly.awardText = response.awardText;
      this.jourly.imagePath = response.imagePath;
      this.jourly.isPreffered = response.isPreffered;
      this.jourly.isFeatured = response.isFeatured;
      this.jourly.storyCount = response.storyCount;
      this.jourly.userId = response.userId;
    },
    async handleEdit() {
      let response =  await RequestService.put('jourly/'+this.jourlyId+'/update',this.jourly);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/jourly/index");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
