<template>
   <div style="height:100vh;">
    <div class="header pb-6 header-top">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-6 col-7">
              <!-- <h6 class="h2 text-white d-inline-block mb-0">Jourlys</h6> -->
              <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                <!-- <route-bread-crumb></route-bread-crumb> -->
              </nav>
            </div>
            <div class="col-lg-6 col-5 text-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt--6" style="background-color:#000000">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Edit Advertisement</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <!-- Form groups used in grid -->
          <form class="needs-validation" @submit.prevent="handleEdit">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Title</label>
                  <div class="has-label">
                    <input class="form-control" name="title" id="title" type="text" placeholder="title" v-model="advertisement.title">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Format</label>
                  <div class="has-label">
                    <select class="form-control" v-model="advertisement.format">
                      <option value="square">Square</option>
                      <option value="banner">Banner</option>
                    </select>
                    <!-- <input class="form-control" name="title" id="title" type="text" placeholder="title" v-model="advertisement.title" required> -->
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Image</label>
                  <div class="has-label">
                    <input class="form-control" name="image" id="image" type="file" placeholder="Image" @change="onChangeFileUpload">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Link <span>*</span></label>
                  <div class="has-label">
                    <input class="form-control" name="link" id="link" type="text" placeholder="link" v-model="advertisement.link" required>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-control-label">Click Count</label>
                  <div class="has-label">
                    <input class="form-control" name="click_count" id="click_count" type="number" placeholder="Click Count" v-model="advertisement.click_count">
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <!-- <div class="form-group">
                  <label class="form-control-label">Code</label>
                  <div class="has-label">
                    <textarea id="code" rows="3" placeholder="Write you code hare ..." class="form-control" v-model="advertisement.code" spellcheck="false"></textarea>
                  </div>
                </div> -->
                <!-- <base-input
                  name="imagePath"
                  label="ImagePath"
                  placeholder="ImagePath"
                  v-model="jourly.imagePath"
                /> -->
              </div>
             
              <div class="col-md-12">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Update</base-button
                  >
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import { Form } from "vee-validate";
import RequestService from '../../services/request.service';
import { useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";


export default {
  name: "form-elements",
  components: {
    RouteBreadCrumb,
    // Form,
  },
  data() {
    return {
      advertisementId : '',
      message : '',
      advertisement:{
	      title: '',
	      link : '',
        image : null,
	      format : 'square',
        click_count: 0
      }
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    async getData(){
      let route = useRoute();
      this.advertisementId = route.params.id;
      let response =  await RequestService.get('advertisement/'+this.advertisementId+'/show');
      this.advertisement.title = response.title;
      this.advertisement.link = response.link;
      this.advertisement.format = response.format;
      this.advertisement.click_count = response.click_count;
    },
    onChangeFileUpload(event) {
      this.advertisement.image = event.target.files[0];
    },
    async handleEdit() {
      const formData = new FormData();
      formData.append('title', this.advertisement.title);
      formData.append('format', this.advertisement.format);
      if(this.advertisement.image){
        formData.append('image', this.advertisement.image);
      }
      formData.append('link', this.advertisement.link);
      formData.append('click_count', this.advertisement.click_count);
      let response =  await RequestService.post2('advertisement/'+this.advertisementId+'/update',formData);
      if(response.status=='200'){
        this.message = response.data.message;
        this.runToast('top-right', 'info');
        this.$router.push("/advertisement/index");
      }else{
        this.message = 'Something went worng. Please try again.';
        this.runToast('top-right', 'warning');
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: this.message,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
